/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import cookie from "react-cookies";
import { GET_STATICBLOCKS_LIST } from "../../actions";
import { apiUrl, unquieID } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst, showDateTimeFormate, showDateFormate, showTimeFormate, showPriceValue } from "../Helpers/SettingHelper";
import filteri from "../../common/images/filter.svg";

import "../../common/css/owl.carousel.css";

var Parser = require("html-react-parser");
var qs = require("qs");
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Transactions",
      transactionList: [],
      staticblocksList: [],
      totalTransaction:0,
      totalTransactionValue:0,
      totalCashTransaction:0,
      totalTransactionCashValue:0,
      termsandcondInfo: "",
      trans_type: "",
      trans_orderby: "",
      search_text: "",
      start_date:"",
      end_date:""
    };

    if (cookie.load("LoginUserId") === undefined) {
      props.history.push("/");
    }

    //this.props.getStaticblocksList("&slug=terms-conditions");
    this.getTransData();
  }
  componentDidMount() {
    //$("body").addClass("hide-overlay");
    /*setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".canceltrans-popup",
          },
          type: "inline",
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );*/
  }

  getTransData() {
    var postObject = {
      app_id: unquieID,
      user_id: cookie.load("LoginUserId"),
      outlet_id: cookie.load("LoginUserOutlet"),
      trans_type: this.state.trans_type,
      trans_orderby: this.state.trans_orderby,
      search_text: this.state.search_text,
    };
    if (this.state.start_date!==null && this.state.start_date !== "" && this.state.end_date!==null && this.state.end_date !== "") {
      postObject.from_date=moment(this.state.start_date).format("Y-MM-DD HH:mm:ss") 
      postObject.to_date= moment(this.state.end_date).format("Y-MM-DD HH:mm:ss");
    } else if (this.state.start_date !== "" && this.state.start_date!==null) {
      postObject.from_date=moment(this.state.start_date).format("Y-MM-DD HH:mm:ss");
    }
    showLoaderLst("trans-page-inner", "class");
    axios
      .post(apiUrl + "customer/transactionlist", qs.stringify(postObject))
      .then((res) => {
        hideLoaderLst("trans-page-inner", "class");
        if (res.data.status === "ok") {
          this.setState({ transactionList: res.data.result , totalTransaction:res.data.totalTransaction, totalTransactionValue:res.data.totalTransactionValue, totalCashTransaction:res.data.totalTransactionCashValue, totalTransactionCashValue:res.data.totalSpendAmount });
        } else {
          this.setState({ transactionList: Array() });
        }
      });
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.staticblocksList !== PropsDt.staticblocks) {
      this.setState({ staticblocksList: PropsDt.staticblocks }, function () {
        this.setMemberInfo();
      });
    }
  }

  setMemberInfo() {
    let staticblocksList = this.state.staticblocksList;
    let termsandcondInfo = "";
    if (Object.keys(staticblocksList).length > 0) {
      const staticblockHtml = staticblocksList.map((staticblock, rwInt) => {
        if (staticblock.staticblocks_slug == "terms-conditions") {
          termsandcondInfo = staticblock.staticblocks_description;
        }
        return staticblock;
      });
    }
    let termsandcondInfoHtml =
      termsandcondInfo != "" ? Parser(termsandcondInfo) : "";
    this.setState({ termsandcondInfo: termsandcondInfoHtml });
  }

  transactionListFun() {
    let transactionList = this.state.transactionList;
    if (Object.keys(transactionList).length > 0) {
      const transactionRowHtml = transactionList.map(
        (transactionRow, rwInt) => {
          var dispyType = '';
          if(transactionRow.transaction_qr_type === "points") {
            dispyType = 'credits';
          }else if(transactionRow.transaction_qr_type === "products" && parseFloat(transactionRow.transaction_qr_usered_amount)>0) {
            dispyType = 'Redeem Points';
          }else if(transactionRow.transaction_qr_type === "products" && parseFloat(transactionRow.transaction_qr_usered_amount)===0) {
            dispyType = 'Redeem Vocuher';
          }else if(transactionRow.transaction_qr_type === "voucherpay") {
            dispyType = 'Cash Vocuher Pay Bill';
          }else if(transactionRow.transaction_qr_type === "billpay") {
            dispyType = 'Pay Bill';
          }
          console.log(transactionRow.transaction_id, transactionRow.transaction_qr_usered_amount, transactionRow.transaction_product_price, transactionRow.transaction_qr_type, 'transactionRow.transaction_qr_type')
          var dispyAmt = '';
          if(parseFloat(transactionRow.transaction_qr_usered_amount)>0 && transactionRow.transaction_qr_type ==="products") {
            dispyAmt = (<strong>
            {transactionRow.transaction_qr_usered_amount} Points
            </strong>);
          }else if(parseFloat(transactionRow.transaction_qr_usered_amount)>0 && parseFloat(transactionRow.transaction_product_price)>0 && transactionRow.transaction_product_id!=="0") {
            dispyAmt = (
              <>
              <strong>
                ${transactionRow.transaction_product_price} Vocuher
              </strong>
              <strong>
                ${transactionRow.transaction_qr_usered_amount} Payment
              </strong>
              </>
            );
          }else if(parseFloat(transactionRow.transaction_qr_usered_amount)>0 && parseFloat(transactionRow.transaction_product_price)===0) {
            dispyAmt = (
              <strong>
                ${transactionRow.transaction_qr_usered_amount} Payment
              </strong>
            );
          }else if(parseFloat(transactionRow.transaction_qr_usered_amount)===0 && parseFloat(transactionRow.transaction_product_price)>0 && transactionRow.transaction_product_id!=="0") {
            dispyAmt = (
              <strong>
                ${transactionRow.transaction_qr_usered_amount} Vocuher
              </strong>
            );
          }else if(parseFloat(transactionRow.transaction_qr_usered_amount)>0) {
            dispyAmt = (
              <strong>
                ${transactionRow.transaction_qr_usered_amount} Payment
              </strong>
            );
          }

          return (
            <li key={rwInt}>
              <ul>
                <li>{transactionRow.transactionID}</li>
                <li className="textcenter"> 
                  {showDateFormate(transactionRow.transaction_created_on)}
                  <br/>
                  {showTimeFormate(transactionRow.transaction_created_on)}
                </li>
                <li>
                  {dispyType}
                </li>
                <li>{(transactionRow.transaction_product_name!=="" && transactionRow.transaction_product_name!==null)?transactionRow.transaction_product_name:'Payment'}</li>
                <li>
                  <span>{transactionRow.customer_first_name}</span>
                  <em>{transactionRow.customer_phone}</em>
                </li>
                <li className="textcenter">
                  {dispyAmt}
                  {/* parseFloat(transactionRow.transaction_qr_usered_amount)>0 && transactionRow.transaction_qr_type ==="products" ?(
                    <strong>
                    {transactionRow.transaction_qr_usered_amount} Points
                    </strong>
                  ):(parseFloat(transactionRow.transaction_qr_usered_amount)>0 &&
                    <>
                    {parseFloat(transactionRow.transaction_product_price>0) &&(
                      <strong>
                      ${transactionRow.transaction_product_price} Vocuher
                      </strong>
                    )}
                    <strong>
                      ${transactionRow.transaction_qr_usered_amount} Payment
                    </strong>
                    </>
                  ) */}
                  {transactionRow.transaction_cancel == "Yes" ? (
                    <a href="javascript:void(0)" className="canceled-lnk">
                      Cancelled
                    </a>
                  ) : transactionRow?.allowtocancel == "yes" ? (
                    <a
                      href="#"
                      onClick={this.actCancelFun.bind(this, transactionRow)}
                    >
                      Cancel
                    </a>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
              {transactionRow.transaction_cancel == "Yes" &&
                (transactionRow.cancelledTransID !== "" ? (
                  <div className="canceled-trans">
                    Cancelled Transaction ID: {transactionRow.cancelledTransID}
                  </div>
                ) : (
                  ""
                ))}                
            </li>
          );
        }
      );

      return <ul>{transactionRowHtml}</ul>;
    } else {
      return (
        <ul>
          <li>
            <p> &nbsp;&nbsp;No Transactions</p>
          </li>
        </ul>
      );
    }
  }

  actCancelFunOld(selectedTrans, event) {
    event.preventDefault();
    if (Object.keys(selectedTrans).length > 0) {
      this.setState({ selected_trans: selectedTrans }, function () {
        $.magnificPopup.open({
          items: {
            src: ".canceltrans-popup",
          },
          type: "inline",
          closeOnBgClick: false,
        });
      });
    } else {
      let tempArr = Array();
      this.setState({ selected_trans: tempArr });
    }
  }

  actCancelFun(selectedTrans, event) {
    event.preventDefault();
    if (Object.keys(selectedTrans).length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to cancel this translation",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel it!",
        customClass: {
          confirmButton: "btn btn-primary me-3 waves-effect waves-light",
          cancelButton: "btn btn-label-secondary waves-effect",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          var postObject = {
            app_id: unquieID,
            user_id: cookie.load("LoginUserId"),
            outlet_id: cookie.load("LoginUserOutlet"),
            customer_id: selectedTrans.transaction_customer_id,
            transaction_id: selectedTrans.transaction_id,
          };

          return axios
            .post(
              apiUrl + "customer/canceltransaction",
              qs.stringify(postObject)
            )
            .then((res) => {
              return res.data;
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        console.log(result, "resultresult");
        if (result.value !== "" && result.value !== undefined) {
          if (result.value.status == "ok") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: result.value.message,
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            }).then((dataarr) => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "Alert",
              text: result.value.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger waves-effect",
              },
            });
          }
        }
      });
    }
  }

  comfirmCancelFun(event) {
    event.preventDefault();
    let selectedTrans = this.state.selected_trans;
    if(Object.keys(selectedTrans).length > 0) {
      var postObject = {
        app_id: unquieID,
        user_id: cookie.load("LoginUserId"),
        outlet_id: cookie.load("LoginUserOutlet"),
        customer_id: selectedTrans.transaction_customer_id,
        transaction_id: selectedTrans.transaction_id
      };
      showLoaderLst('cancel-btn-cls','class');
      axios.post(apiUrl + "customer/canceltransaction", qs.stringify(postObject))
      .then((res) => {
        hideLoaderLst('cancel-btn-cls','class');
        if(res.data.status === "ok") {
          this.getTransData();
          $.magnificPopup.close();
        }
       });
    }
  }

  searchandFilter(event) {
    let trans_type = this.state.trans_type;
    let trans_orderby = this.state.trans_orderby;
    let search_text = this.state.search_text;
    if (event.target.name === "trnstype") {
      trans_type = event.target.value;
    } else if (event.target.name === "filter") {
      trans_orderby = event.target.value;
    } else if (event.target.name === "search_text") {
      search_text = event.target.value;
    }
    this.setState(
      {
        trans_type: trans_type,
        trans_orderby: trans_orderby,
        search_text: search_text,
      },
      function () {
        this.getTransData();
      }
    );
  }

  handleChangeDate(name, value) {
    this.setState({ [name]: value }, ()=> {
      this.getTransData()
    });
  }

  render() {
    let termsandcondInfo = this.state.termsandcondInfo;
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel trans-page-inner">
          <div className="container">
          <div className="trans-summary">
              <ul>
                <li>Total Transaction : <b>{this.state.totalTransaction}</b></li>
                <li>Total Volume : <b>{showPriceValue(this.state.totalTransactionValue)}</b></li>
                
              </ul>
              <ul>
              <li>Cash Voucher Sold : <b>{this.state.totalCashTransaction}</b></li>
                <li>Cash Vocuher Redeemed : <b>{showPriceValue(this.state.totalTransactionCashValue)}</b></li>
              </ul>
            </div>
            <div className="history-search">
              <div className="history-search-form">
                <input
                  type="text"
                  name="search_text"
                  placeholder="Search Customer"
                  value={this.state.search_text}
                  onChange={this.searchandFilter.bind(this)}
                />
              </div>
            </div>
            <div className="history-filter">
              <img src={filteri} className="hf-icon" />
              <div className="hf-lhs">
                <label>Filter by :</label>
                <select
                  name="filter"
                  value={this.state.trans_orderby}
                  onChange={this.searchandFilter.bind(this)}
                >
                  <option value="">select</option>
                  <option value="low">Low to High</option>
                  <option value="high">High to Low</option>
                </select>
              </div>
              <div className="hf-rhs">
                <select
                  name="trnstype"
                  value={this.state.trans_type}
                  onChange={this.searchandFilter.bind(this)}
                >
                  <option value="">Type</option>
                  <option value="points">Credits</option>
                  <option value="products">Products</option>
                </select>
              </div>
            </div>
            <div className="filte-date">
              <div className="start_date">
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    dropdownMode="select"
                    className="form-control"
                    selected={this.state.start_date}
                    dateFormat="d-MM-yyyy h:mm aa"
                    placeholderText="Start Date"
                     onChange={this.handleChangeDate.bind(this, "start_date")}
                  />
                </div>
                <div>
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    dropdownMode="select"
                    className="form-control"
                    selected={this.state.end_date}
                    minDate={
                      this.state.start_date !== ""
                        ? this.state.start_date
                        : ""
                    }
                    dateFormat="d-MM-yyyy h:mm aa"
                    placeholderText="End Date"
                     onChange={this.handleChangeDate.bind(this, "end_date")}
                  />
                </div>
            </div>
            <div className="history-table">
              <div className="history-table-header">
                <ul>
                  <li className="textcenter">Transaction ID</li>
                  <li className="textcenter">Date/Time</li>
                  <li className="textcenter">Type</li>
                  <li className="textcenter">Voucher Name</li>
                  <li className="textcenter">Customer</li>
                  <li className="textcenter">Value</li>
                </ul>
              </div>
              <div className="history-table-body">
                {this.transactionListFun()}
              </div>
            </div>
          </div>
        </div>

        <div
          id="canceltrans-popup"
          className="white-popup mfp-hide popup_sec canceltrans-popup"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">Transactions</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_right">
                  <h5>Are you sure you want to cancel this transaction?...</h5>
                  <div
                    className="button cancel-btn-cls"
                    onClick={this.comfirmCancelFun.bind(this)}
                  >
                    Yes Confirm
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var staticblocksArr = Array();
  if (Object.keys(state.staticblocks).length > 0) {
    if (state.staticblocks[0].status === "ok") {
      staticblocksArr = state.staticblocks[0].result_set;
    }
  }
  return {
    staticblocks: staticblocksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticblocksList: (params) => {
      dispatch({ type: GET_STATICBLOCKS_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Transactions));
